<template lang="html">
  <div class="usluga">

  <div class="headUsluga">

    <router-link class="navbar-brand" to="/ksusluge">Back </router-link>
    <h3>Assessment Procena Timova</h3>
    <p>Procena timova daje informacije o potencijalima, ograničenjima I kapacitetima zaposlenih u timu, na osnovu čega se može predvideti timski uspeh. </p>
    <p>Ovom metodologijom se dobija uvid u kompetencije kandidata prilikom selekcije za određenu poziciju u okviru organizacije, daje informacije o njihovim snagama i slabostima i omogućava poređenje kandidata.</p>
    <p>Podrazumeva korišćenje psiholoških testova, testova liderskih sposobnosti, kao i testova inteligencije, kreiranje assessment centra za procenu zaposlenih.</p>
    <p>Usluga se preporučuje za procenu Menadžment pozicija svih nivoa u kompaniji</p>
    <h5>Potrebna Vam je ova usluga? Popunite polja ispod:</h5>
      </div>
    <div class="card-body">



         <h4>Testovi za koje ste zainteresovani</h4>
         <div class="form-check">
           <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="test1" value="Psihološki test">
           <label class="form-check-label" for="exampleCheck1">Psihološki test</label>
         </div>
         <div class="form-check">
           <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="test2" value="Test liderskih sposobnosti">
           <label class="form-check-label" for="exampleCheck1">Test liderskih sposobnosti</label>
         </div>
         <div class="form-check">
           <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="test3" value="Test inteligencije">
           <label class="form-check-label" for="exampleCheck1">Test inteligencije</label>
         </div>
         <div class="form-check">
           <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="test4" value="Assessment centar">
           <label class="form-check-label" for="exampleCheck1">Assessment centar</label>
         </div>
         <div class="form-check">
           <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="test5" value="Struktuirani Intervju">
           <label class="form-check-label" for="exampleCheck1">Struktuirani Intervju</label>
         </div>
         <div class="form-check">
           <input type="checkbox" class="form-check-input" id="exampleCheck1" v-model="test6" value="Test opštih i spacijalnih sposobnosti">
           <label class="form-check-label" for="exampleCheck1">Test opštih i spacijalnih sposobnosti</label>
         </div>

         <div class="form-group">
           <label for="numberOfEmployees">Broj zaposlenih za koje se vrši usluga</label>
           <input
             class="form-control"
             type="text"
             placeholder=""
             name="numberOfEmployees"
             v-model="numberOfEmployees"
             id="numberOfEmployees"
             >
         </div>
        <div class="form-group">
          <label for="companyName">Naziv Kompanije<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="companyName"
            v-model="companyName"
            id="companyName"
            >
        </div>
        <div class="form-group">
          <label for="contactName">Ime i Prezime Kontakt Osobe<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactName"
            v-model="contactName"
            id="contactName"
            >
        </div>
        <div class="form-group">
          <label for="contactNumber">Kontakt telefon<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactNumber"
            v-model="contactNumber"
            id="contactNumber"
            >
        </div>
        <div class="form-group">
          <label for="email">E-mail<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="email"
            v-model="email"
            id="email"
            >
        </div>



        <div class="validation">
          {{validation}}
        </div>
        <button class="btn btn-primary" @click="submit">Posalji Formular</button>
        &nbsp;&nbsp;&nbsp;&nbsp;



  </div>

  </div>


</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {

  name: 'AssessmentProcenaTimova',
  data(){
    return{
      companyName:"",
  contactName:"",
  contactNumber:"",
  email:"",
  numberOfEmployees :"",
  validation:"",
  date:"",
    }
  },
  methods:{
  ...mapActions(['submitServiceAplication']),
  submit(){

    this.validation="";

    if(this.companyName==""){
      this.validation="Unesite naziv kompanije."
    }
    if(this.contactName==""){
      this.validation="Unesite ime i prezime kontakt osobe."
    }
    if(this.email==""){
      this.validation="Unesite email."
    }
    if(this.contactNumber==""){
      this.validation="Unesite kontakt telefon."
    }
    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    this.date= "Datum: "+day+"."+month+"."+year+".";


    let text = [];
    text.push("Assessment Procena Timova");
    text.push(this.date);
    text.push("Naziv Kompanije: "+this.companyName);
    text.push("Ime i Prezime Kontakt Osobe: "+this.contactName);
    text.push("Email: "+this.email);
    text.push("Kontakt telefon: "+this.contactNumber  );
    text.push("Broj zaposlenih u kompaniji: "+this.numberOfEmployees);
    let strTestovi="Testovi za koje ste zainteresovani: ";
    if(this.test1){
      strTestovi+="Psihološki test,"
    }
    if(this.test2){
      strTestovi+="Test liderskih sposobnosti,"
    }
    if(this.test3){
      strTestovi+="Test inteligencije,"
    }
    if(this.test4){
      strTestovi+="Assessment centar,"
    }
    if(this.test5){
      strTestovi+="Struktuirani Intervju,"
    }
    if(this.test6){
      strTestovi+="Test opštih i spacijalnih sposobnosti,"
    }
    text.push(strTestovi);

    let data ={
      formular:text
    }

    if(this.validation==""){
    this.submitServiceAplication(data);
    this.validation="Uspesno poslat formular."
    }

  },
},
  beforeCreate: function() {
        document.body.className = 'usluge';
  }
}
</script>

<style lang="css" scoped>
.usluga{
  position: absolute;
  top: auto;
  left: 10%;
  right: 10%;
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin-top: 15px;

}
.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
